/**
 * Common functions and state management
 */
import Promise from "bluebird";

const branding = require("./branding").getBranding();

export const documentReady = new Promise(resolve => {
    if (document.readyState === "complete") {
        resolve(true);
        return;
    }
    document.addEventListener("DOMContentLoaded", () => {
        resolve(true);
    });
});
export const isFirefox = navigator.userAgent.includes("Firefox");

export const MANUALS_SEARCH_PROVIDERS = [
    { name: branding.SearchEngineName, id: branding.SearchId, isDefault: true },
    {
        name: "Manuals Lib",
        id: "manualslib",
        isDefault: false,
        url: "https://www.manualslib.com/s/",
    },
    {
        name: "Manuals Online",
        id: "manualsonline",
        isDefault: false,
        url: "http://www.manualsonline.com/search.html?q=",
    },
];

export const WEB_SEARCH_PROVIDERS = {
    yahoo: { name: "Yahoo", id: "yahoo" },
    google: {
        name: "Google",
        id: "google",
        url: "https://google.com/search?q={{QUERY}}",
    },
    bing: {
        name: "Bing",
        id: "bing",
        url: "https://bing.com/search?q={{QUERY}}",
    },
};

export const DEFAULT_WEB_SEARCH_PROVIDER = "yahoo";
