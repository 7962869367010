/**
 * Autocomplete settings and utilities
 */
import _ from "lodash";
import queryString from "query-string";
import { SUGGESTION_API, SUGGESTION_API_INDEXER } from "./urls";
import freshManuals from "./freshmanuals";

const ONE_MINUTE_MS = 60 * 1000;

// We will setup this to allow a call ever 10 minutes
const clearCache = _.debounce(() => {
    manualsSuggestionProvider.cache.clear();
    manualsSuggestionWithIndexerProvider.cache.clear();
    freshManualsSuggestionProvider.cache.clear();
}, 10 * ONE_MINUTE_MS);
let manualsSuggestionWithIndexerProvider = _.memoize(async term => {
    clearCache(); // Make sure the cache doesnt get too big
    const qs = {
        format: "json",
    };
    const res = await fetch(
        `${SUGGESTION_API}${term}/?${queryString.stringify(qs)}`
    );
    const res2 = await fetch(
        `${SUGGESTION_API_INDEXER}?q=${term}&pageNo=0&perPage=10`
    );
    if (!res.ok && !res2.ok) {
        return await res
            .json()
            .catch(e => ({ error: "Bad Response", query: term, results: [] }));
    }
    const data = await res.json();
    const data2 = await res2.json();
    return {
        query: term,
        ...data,
        ...data2,
    };
});

function makeIndexerAutoCompleteFunction(isFreshManuals) {
    return async function(term) {
        clearCache(); // Make sure the cache doesnt get too big

        const body = {
            q: term,
            field: "resultEntity",
            perPage: 20,
        };
        let qs = "";
        if (isFreshManuals) {
            body.field = "title";
            qs = `?group=${freshManuals.group}`;
            if (freshManuals.staging) {
                body.staging = true;
            }
        }

        const res = await fetch(
            `${SUGGESTION_API_INDEXER}/post/complete${qs}`,
            {
                method: "POST",
                body: JSON.stringify(body),
            }
        );
        if (!res.ok) {
            return await res.json().catch(e => ({
                error: "Bad Response",
                query: term,
                results: [],
            }));
        }
        const data = await res.json();
        return {
            query: term,
            ...data,
        };
    };
}

const manualsSuggestionWithIndexerProviderONLY = _.memoize(
    makeIndexerAutoCompleteFunction(false)
);
const freshManualsSuggestionProvider = _.memoize(
    makeIndexerAutoCompleteFunction(true)
);

let manualsSuggestionProvider = _.memoize(async term => {
    clearCache(); // Make sure the cache doesnt get too big
    const qs = {
        format: "json",
    };
    const res = await fetch(
        `${SUGGESTION_API}${term}/?${queryString.stringify(qs)}`
    );
    if (!res.ok) {
        return await res
            .json()
            .catch(e => ({ error: "Bad Response", query: term, results: [] }));
    }
    const data = await res.json();
    return {
        query: term,
        ...data,
    };
});

export {
    manualsSuggestionProvider,
    manualsSuggestionWithIndexerProvider,
    manualsSuggestionWithIndexerProviderONLY,
    freshManualsSuggestionProvider,
};
