<template>
    <!--main wrapper-->
    <div class="serp-wrapper">
        <!-- Bookmark Popup  -->
        <first-run
            :which="whichPop"
            :onClose="onCloseHelp"
            :onNext="onNextPop"
        />
        <!--header wrapper-->
        <div class="header-wrapper">
            <!--nav-->
            <div id="serp-nav">
                <div
                    id="job-btn"
                    class="serp-nav-item"
                    v-bind:class="{ active: isManualsSearch }"
                    @click="toManualsSearch()"
                >
                    Manuals Search
                </div>
                <div
                    id="web-btn"
                    class="serp-nav-item "
                    v-if="!isFirefox"
                    v-bind:class="{ active: isWebSearch }"
                    @click="toWebSearch()"
                >
                    Web Search
                </div>
            </div>
            <!--End of nav-->
            <!--header-->
            <div class="serp-header">
                <div class="serp-logo"><img :src="branding.Logo" /></div>
                <div class="thisSearch" v-show="isManualsSearch">
                    <div class="search pad-right">
                        <input
                            ref="manualsQuery"
                            id=""
                            placeholder="Search for manual "
                            v-model="query"
                            @keydown.enter="onManualsReturn()"
                        />
                        <auto-complete-input
                            id=""
                            v-model="query"
                            ref="manualsQuery"
                            placeholder="Search manuals title or keyword"
                            :provider="manualsSuggestionProvider"
                            @selected="onSugSelected()"
                            @blur="onLeaveSearch()"
                            @focus="onEnterSearch()"
                            @return="onManualsReturn()"
                        />
                        <div class="s-icon">
                            <i class="fa fa-book" aria-hidden="true"></i>
                        </div>
                        <div class="error" v-if="queryError">
                            This field is required, Pelase enter
                        </div>
                    </div>
                    <button id="btn-search" @click="onManualsReturn()">
                        Search Manuals
                    </button>
                </div>
                <div class="webSearch" v-show="isWebSearch">
                    <div class="search web">
                        <input
                            ref="webSearch"
                            id=""
                            placeholder="Search the web"
                            v-model="wQuery"
                            @keydown.enter="startWebSearch()"
                        />
                        <div class="s-icon">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                        </div>
                        <div class="error" v-if="queryError">
                            This field is required, Pelase enter
                        </div>
                    </div>
                    <button id="btn-search" @click="startWebSearch()">
                        Web Search
                    </button>
                </div>
            </div>
            <!--End of Header-->
        </div>
        <!--End of header wrapper-->
        <!--serp results-->
        <div class="bodyWrapper">
            <div class="serp-results">
                <!--Left col-->
                <div class="serp-results-left">
                    <!-----First Run Add on------>
                    <div class="firstRun-text" v-show="canShowIntroBox">
                        <div class="bclose" @click="onCloseIntroBox()">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <strong
                            >Welcome to
                            {{
                                branding.ProductName || "Manuals Search"
                            }}</strong
                        >
                        <ul>
                            <li>
                                <i class="fa fa-circle" aria-hidden="true"></i
                                >Search our database for Manuals by typing in
                                Product name in the search box above.
                            </li>
                            <li>
                                <i class="fa fa-circle" aria-hidden="true"></i
                                >You can also browse our manuals database by
                                "brand' or 'category' located on the left side
                                menu of the page.
                            </li>
                        </ul>
                    </div>
                    <!-----End of First Run Add on------>
                    <div
                        class="serp-stats"
                        v-show="manualsSearchNum > 0 && activeBrowseList == ''"
                    >
                        Showing {{ manualsSearchNum }} Manuals for
                        <span class="kw">{{ query }}</span>
                    </div>
                    <!--NoResults-->
                    <div
                        class="serp-noresults"
                        v-show="
                            !hasSearchResults && query != '' && searchCompleted
                        "
                    >
                        <h3>
                            The search <span class="kw">{{ query }}</span> did
                            not match any manuals. Try the suggestions below or
                            type a new query above.
                        </h3>
                        <ul>
                            <li>Check your spelling.</li>
                            <li>Try more general keywords</li>
                            <li>Try model serial numbers</li>
                            <li>Replace abbreviations with the entire word</li>
                        </ul>
                        <h2>Still not found your Manual !</h2>
                        <h3 class="bs">
                            We try our best to keep up our database up to date,
                            but unfortunately with hundreds of new products
                            released every day, it is sometimes hard to account
                            for all new updates. Try the links below to some
                            other resources that may help with your search.
                        </h3>
                        <br />
                        <p>
                            Option 1 -
                            <a
                                class="other-res"
                                v-bind:href="
                                    'https://www.manualslib.com/s/' +
                                        query +
                                        '.html'
                                "
                                title="Search for my Manual"
                                target="_blank"
                                >Search for my Manual</a
                            >
                        </p>
                        <p>
                            Option 2 -
                            <a
                                class="other-res"
                                v-bind:href="
                                    'http://www.manualsonline.com/search.html?q=' +
                                        query
                                "
                                title="Search for my Manual"
                                target="_blank"
                                >Search for my Manual</a
                            >
                        </p>
                    </div>
                    <!-- End of NoResults-->
                    <div v-show="manualsSearchNum && activeBrowseList == ''">
                        <!--results-->
                        <div
                            class="srl has-image"
                            v-for="manual in searchedFilteredManuals"
                            @click="openManual(manual.man_url)"
                        >
                            <div class="srl-img">
                                <img src="/assets/images/manuals.png" />
                            </div>
                            <div class="srl-info">
                                <h3 class="srl-title">
                                    <a
                                        v-bind:href="manual.man_url"
                                        target="_blank"
                                        >{{ manual.product_name }}</a
                                    >
                                </h3>
                                <div class="srl-cat">
                                    <strong>Category:</strong
                                    >{{ manual.category }}
                                </div>
                                <div class="srl-brand">
                                    <strong>Brand:</strong>{{ manual.brand }}
                                </div>
                                <div class="srl-lang">
                                    <strong>Languages:</strong
                                    >{{ getManualLanguage(manual) }}
                                </div>
                            </div>
                        </div>
                        <!--end of results-->
                    </div>
                    <div v-show="activeBrowseList != ''">
                        <!--Browse Nav-->
                        <div class="manual-nav" v-if="activeBrowseList == 'b'">
                            <span>Browse by brands</span>
                        </div>
                        <div
                            class="manual-nav"
                            v-else-if="activeBrowseList == 'c'"
                        >
                            <span>Browse by categories</span>
                        </div>
                        <!-- End of Browse nav-->
                        <!---Filter Alphabet --->
                        <div class="manuals-filter-bar-container">
                            <ul>
                                <li><a href="#symbols">#</a></li>
                                <li
                                    v-for="char in 'abcdefghijklmnopqrstuvwxyz'.split(
                                        ''
                                    )"
                                >
                                    <a v-bind:href="'#' + char.toUpperCase()">{{
                                        char.toUpperCase()
                                    }}</a>
                                </li>
                            </ul>
                        </div>
                        <!---End of Filter Alphabet --->
                        <!--Browse Panel-->
                        <div class="manuals-browse-panel">
                            <div id="symbols">
                                <div
                                    v-for="char in 'abcdefghijklmnopqrstuvwxyz'.split(
                                        ''
                                    )"
                                >
                                    <div v-bind:id="char.toUpperCase()"></div>
                                    <div
                                        class="manuals-browse-panel-title-text"
                                    >
                                        {{ char.toUpperCase() }}
                                    </div>
                                    <ul class="listings">
                                        <li
                                            class="brands-detail-link"
                                            v-bind:list="activeBrowseList"
                                            @click="doSearch(item)"
                                            v-for="item in getBrowseListByLetter(
                                                char
                                            )"
                                        >
                                            {{ item }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Browse Panel--
                    <!--End of left col-->
                    <!--right col-->
                    <div class="serp-results-right"></div>
                    <!--End of right col-->
                </div>
                <!--Right col -->
                <div class="rightCol">
                    <!--Browse-->
                    <div class="module-right side-list browse">
                        <h2>
                            <i class="fa fa-eye" aria-hidden="true"></i>Browse
                            By
                        </h2>
                        <div
                            class="list"
                            v-bind:class="{ select: activeBrowseList == 'b' }"
                            id="browseBrands"
                            @click="showBrowseList('b')"
                        >
                            Browse by Brand
                        </div>
                        <div
                            class="list"
                            v-bind:class="{ select: activeBrowseList == 'c' }"
                            id="browseCats"
                            @click="showBrowseList('c')"
                        >
                            Browse by Category
                        </div>
                    </div>
                    <!--end of Browse-->
                    <!--Filter-->
                    <div class="module-right filter" v-show="manualsSearchNum">
                        <h2>
                            <i class="fa fa-filter" aria-hidden="true"></i
                            >Filter By:
                        </h2>
                        <div class="category custom-select">
                            <h3>Category:</h3>
                            <ul id="categories">
                                <li v-for="(item, key) in manCountByCatFilter">
                                    <a
                                        @click="
                                            filterCategoryResults($event, key)
                                        "
                                        href="#"
                                        v-bind:title="key"
                                        >{{ key }} ({{ item }})</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="brand">
                            <h3>Brand:</h3>
                            <ul id="brands">
                                <li
                                    v-for="(item, key) in manCountByBrandFilter"
                                >
                                    <a
                                        @click="filterBrandResults($event, key)"
                                        href="#"
                                        v-bind:title="key"
                                        >{{ key }} ({{ item }})</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--End of filter-->
                    <!--Search History-->
                    <div class="module-right side-list history">
                        <h2>
                            <i class="fa fa-history" aria-hidden="true"></i
                            >Recent searches
                        </h2>
                        <div
                            class="list"
                            v-for="recent in recentSearches"
                            @click="doRecentSearch(recent)"
                        >
                            {{ recent }}
                        </div>
                    </div>
                    <!--end of search history-->
                </div>
                <!--End of Right col-->
            </div>
        </div>
        <!--End of resultes-->
        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter">
                    <img :src="branding.GrayLogo" /><span
                        ><span id="brandDetails"
                            ><i class="fa fa-copyright" aria-hidden="true"></i>
                            {{ branding.Trademark }}</span
                        >
                    </span>
                </div>
                <ul>
                    <li>
                        <a
                            :href="
                                `https://${
                                    branding.RootDomain
                                }/terms-of-service/`
                            "
                            >Terms of Service
                        </a>
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/privacy-policy/`
                            "
                            >Privacy</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/contact-us/`"
                            >Contact Us</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/uninstall/`">
                            Uninstall</a
                        >
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${
                                    branding.RootDomain
                                }/privacy-policy/#cali-consumer`
                            "
                            target="_blank"
                            >Do Not Sell My Personal Information</a
                        >
                    </li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
    <!-- End of main wrapper -->
</template>
<script>
import bp from "babel-polyfill";
import queryString from "query-string";
import "whatwg-fetch";
import Humanize from "humanize-plus";
import { getSetting, setSetting, getGuid } from "./storage";
import { WEB_SEARCH_URL, openUrl, WEB_SERVICE_URL } from "./urls";
import FirstRun from "./components/FirstRun.vue";
import AutoCompleteInput from "./components/AutoCompleteInput.vue";
import { manualsSuggestionProvider } from "./autocomplete";
import { getBranding } from "./branding";
import { documentReady, MANUALS_SEARCH_PROVIDERS, isFirefox } from "./common";
import "./analytics";

function validatePop(name) {
    switch (name) {
        case "intro":
        case "i":
            return "intro";
        case "t":
        case "tutorial":
            return "tutorial";
    }
    return false;
}

export default {
    name: "serp",
    components: {
        "first-run": FirstRun,
        "auto-complete-input": AutoCompleteInput,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            manualsSearchProviders: MANUALS_SEARCH_PROVIDERS,
            recentSearches: [],
            brands: [],
            categories: [],
            isManualsSearch: true,
            isWebSearch: false,
            searchedManuals: [],
            searchCompleted: false,
            activeBrowseList: "",
            filterBrand: "",
            filterCategory: "",
            query: "",
            wQuery: "",
            queryError: false,
            guid: qs.guid,
            isFirstRun: validatePop(qs.pop) || false,
            introBoxClosed: false,
            manualsEngine: qs.engine,
            whichPop: validatePop(qs.pop) || false,
            branding: getBranding(),
            searchFocus: false,
            page: qs.page || "",
            isFirefox,
        };
    },
    mounted() {
        documentReady.then(async () => {
            this.recentSearches =
                JSON.parse(getSetting("recentSearches")) || [];
            this.brands = JSON.parse(getSetting("brands")) || [];
            this.categories = JSON.parse(getSetting("categories")) || [];
            try {
                this.guid = await getGuid();
            } catch (e) {}
            if (this.isFirstRun != false) {
                this.showBrowseList("b");
            }
            if (this.brands.length == 0) {
                await this.loadBrands();
            }
            if (this.categories.length == 0) {
                await this.loadCategories();
            }
            this.$refs.manualsQuery.focus();
            this.handleSearchUrl();
            if (this.page !== "") {
                switch (this.page) {
                    case "cat":
                        this.activeBrowseList = "c";
                        break;
                    case "brand":
                        this.activeBrowseList = "b";
                        break;
                    default:
                        this.handleSearchUrl();
                }
            } else {
                if (window.location.hash.split("#").indexOf("brand") != -1)
                    this.showBrowseList("b");
                if (window.location.hash.split("#").indexOf("cat") != -1)
                    this.showBrowseList("c");
            }
        });
    },
    async created() {
        window.onpopstate = () => {
            this.handleSearchUrl();
        };
    },
    methods: {
        manualsSuggestionProvider,
        async makeCall(url) {
            const recordsReq = await fetch(url);
            if (recordsReq.ok) {
                const recordsRes = await recordsReq.json();
                return recordsRes;
            }
            return null;
        },
        async loadBrands(url) {
            if (!url) url = `${WEB_SERVICE_URL}brands/?limit=500`;
            const data = await this.makeCall(url);
            if (data != null) {
                for (const b in data.results) {
                    this.brands.push(data.results[b].name);
                }
                setSetting("brands", JSON.stringify(this.brands));
            }
        },
        async loadCategories(url) {
            if (!url) url = `${WEB_SERVICE_URL}categories/?limit=500`;
            const data = await this.makeCall(url);
            if (data != null) {
                for (const c in data.results) {
                    this.categories.push(data.results[c].name);
                }
                setSetting("categories", JSON.stringify(this.categories));
            }
        },
        showBrowseList(list) {
            this.activeBrowseList = list;
            if (list == "b") this.browseList = this.brands;
            else if (list == "c") this.browseList = this.categories;
            this.searchedManuals = [];
            this.query = "";
            // hide that blue box when user directly opens one of the lists
            this.introBoxClosed = true;
        },
        getBrowseListByLetter(letter) {
            if (this.activeBrowseList == "b")
                return this.brands.filter(
                    i => i.toLowerCase().indexOf(letter) == 0
                );
            if (this.activeBrowseList == "c")
                return this.categories.filter(
                    i => i.toLowerCase().indexOf(letter) == 0
                );
        },
        startWebSearch() {
            const searchParams = {
                k: this.wQuery,
                guid: this.guid,
            };
            openUrl(
                `${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`,
                false
            );
        },
        validateManualsSearch(withFocus) {
            const { query } = this;
            let errs = false;
            if (query == "") {
                errs = errs || {};
                errs.query = "This field is required, Please enter";
            }
            this.queryError = errs;
            if (withFocus) {
                if (errs.query) this.$refs.manualsQuery.focus();
            }
            return errs === false;
        },
        async onManualsReturn() {
            if (!this.validateManualsSearch(true)) return;
            await this.doSearch(this.query);
        },
        async doSearch(term) {
            if (typeof this.manualsEngine === "undefined")
                this.manualsEngine = this.manualsSearchProviders[0].id;
            else if (this.manualsEngine == "")
                this.manualsEngine = this.manualsSearchProviders[0].id;
            if (this.manualsEngine != this.manualsSearchProviders[0].id) {
                if (this.manualsEngine == this.manualsSearchProviders[1].id) {
                    openUrl(
                        `${this.manualsSearchProviders[1].url + term}.html`,
                        false
                    );
                } else if (
                    this.manualsEngine == this.manualsSearchProviders[2].id
                ) {
                    openUrl(this.manualsSearchProviders[2].url + term, false);
                }
            } else {
                this.query = term;
                if (this.recentSearches.unshift(term) > 5) {
                    this.recentSearches.pop();
                }
                this.filterBrand = "";
                this.filterCategory = "";
                setSetting(
                    "recentSearches",
                    JSON.stringify(this.recentSearches)
                );
                this.searchCompleted = false;
                let url = "";
                if (this.activeBrowseList == "") {
                    url = `${WEB_SERVICE_URL}search/${term}/?results=all`;
                } else if (this.activeBrowseList == "b") {
                    url = `${WEB_SERVICE_URL}brands/${term}/?results=500`;
                } else if (this.activeBrowseList == "c") {
                    url = `${WEB_SERVICE_URL}categories/${term}/?results=500`;
                }
                const data = await this.makeCall(url);
                if (data != null) {
                    if (this.activeBrowseList == "")
                        this.searchedManuals = data[1].manuals;
                    else this.searchedManuals = data.results;
                    this.introBoxClosed = this.searchedManuals.length > 0;
                    this.searchCompleted = true;
                    this.activeBrowseList = "";
                }
            }
        },
        filterCategoryResults(ev, category) {
            ev.preventDefault();
            this.filterCategory = category;
            this.filterBrand = "";
        },
        filterBrandResults(ev, brand) {
            ev.preventDefault();
            this.filterCategory = "";
            this.filterBrand = brand;
        },
        toWebSearch() {
            this.isManualsSearch = false;
            this.isWebSearch = true;
            this.$nextTick(() => this.$refs.webSearch.focus());
        },
        toManualsSearch() {
            this.isManualsSearch = true;
            this.isWebSearch = false;
            this.$nextTick(() => {
                this.$refs.manualsQuery.focus();
            });
        },
        async handleSearchUrl() {
            const qs = queryString.parse(window.location.search);
            const { q } = qs;
            this.query = q || "";
            if (this.query) {
                await this.doSearch(this.query);
            }
        },
        onNextPop(tag) {
            this.whichPop = validatePop(tag) || false;
        },
        onCloseHelp() {
            switch (this.whichPop) {
                case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
            }
            this.whichPop = false;
        },
        async doRecentSearch(term) {
            this.query = term;
            await this.doSearch(this.query);
        },
        getManualLanguage(manual) {
            return manual.languages.map(elem => elem.language).join(",");
        },
        async onSugSelected() {
            this.$refs.manualsQuery.focus();
            if (!this.validateManualsSearch(true)) return;
            await this.doSearch(this.query);
        },
        onEnterSearch() {
            window.setTimeout(() => {
                this.searchFocus = true;
            }, 100);
        },
        onLeaveSearch() {
            window.setTimeout(() => {
                this.searchFocus = false;
            }, 500);
        },
        openManual(url) {
            openUrl(url);
        },
        onCloseIntroBox() {
            this.introBoxClosed = true;
        },
    },
    computed: {
        canShowIntroBox() {
            if (this.introBoxClosed) return false;
            if (!this.isFirstRun) {
                // force to show blue box and brand lists when you land on the page without pop=i
                return !this.hasSearchResults || this.query == "";
            }
            return this.isFirstRun;
        },
        hasSearchResults() {
            return this.manualsSearchNum > 0;
        },
        manualsSearchNum() {
            return this.searchedFilteredManuals.length;
        },
        searchedFilteredManuals() {
            if (this.filterCategory == "" && this.filterBrand == "")
                return this.searchedManuals;
            if (this.filterCategory != "") {
                return this.searchedManuals.filter(
                    item => item.category === this.filterCategory
                );
            }
            if (this.filterBrand != "") {
                return this.searchedManuals.filter(
                    item => item.brand === this.filterBrand
                );
            }
        },
        manCountByCatFilter() {
            const r = {};
            let i = 0;
            for (i = 0; i < this.searchedManuals.length; i++) {
                if (r[this.searchedManuals[i].category])
                    r[this.searchedManuals[i].category] += 1;
                else r[this.searchedManuals[i].category] = 1;
            }
            return r;
        },
        manCountByBrandFilter() {
            const r = {};
            let i = 0;
            for (i = 0; i < this.searchedManuals.length; i++) {
                if (r[this.searchedManuals[i].brand])
                    r[this.searchedManuals[i].brand] += 1;
                else r[this.searchedManuals[i].brand] = 1;
            }
            return r;
        },
    },
};
</script>
