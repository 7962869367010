<template>
    <!--Light Box-->
    <div class="lightbox" v-if="which">
        <!--First Run-->
        <div class="card firstrun" v-if="isIntro">
            <div class="close" @click="onCloseClick">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <img :src="branding.Logo" />
            <h1>
                Welcome and Thank You for adding
                {{ branding.ProductName || "Manuals Search" }} Extension.
            </h1>
            <ul class="fr">
                <li>
                    Search our database for manuals by typing in product name,
                    serial number or product model.
                </li>
                <li>
                    You can also browse our manuals database by "brand' or
                    'category' on the left column of the page.
                </li>
            </ul>
            <div class="btnWrapper">
                <button class="ok left" @click="onCloseClick()">
                    Search Manuals
                </button>
                <button class="ok left fade" @click="pickLearnTips()">
                    Quick Tips
                </button>
            </div>
        </div>
        <!-- End of First Run-->
        <!--Tips-->
        <div class="card firstrun" v-else-if="isTutorial">
            <div class="close" @click="onCloseClick">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <img :src="branding.Logo" />
            <h1>
                Getting the most out of
                {{ branding.ProductName || "Manuals Search" }}
            </h1>
            <img :src="branding.TypeGif" class="type" />
            <ol>
                <li>
                    Go to the address bar and type <strong>"manuals"</strong>
                </li>
                <li>
                    After <strong>"manuals"</strong> hit spacebar then the
                    product name or brand or product category you would like to
                    search.<br />
                    (e.g. <strong>Motorola, Apple, Dyson Supersonic </strong>)
                </li>
            </ol>
            <div class="btnWrapper">
                <button class="ok left" v-show="false">Back</button>
                <button class="ok left" @click="onCloseClick()">OK</button>
            </div>
        </div>
        <!-- End Tips-->
    </div>
    <!--End of light box-->
</template>
<script>
import { arrow } from "@tightrope/newtab";
import { getBranding } from "../branding";

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

export default {
    name: "first-run",
    props: ["which", "onClose", "onNext"],
    data() {
        return {
            branding: getBranding(),
        };
    },
    mounted() {
        arrow("newtab");
    },
    methods: {
        canShow(name) {
            return this.which && this.which.endsWith(name);
        },
        pickLearnTips() {
            switch (this.which) {
                case "intro":
                    this.onNext("t");
            }
        },
        onCloseClick() {
            this.onClose && this.onClose(...arguments);
        },
    },
    computed: {
        isTutorial() {
            return this.canShow("tutorial");
        },
        isIntro() {
            return this.canShow("intro");
        },
    },
};
</script>
