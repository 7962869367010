import { getBranding } from "./branding";

const branding = getBranding();

// <!-- Google Analytics -->
(function(i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    (i[r] =
        i[r] ||
        function() {
            (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
})(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
);

ga("create", branding.GA_ID, "auto");
ga("send", "pageview");

if (branding.GTM_ID) {
    // GTM
    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l != "dataLayer" ? `&l=${l}` : "";
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", branding.GTM_ID);
}

export function trackGA(cat, action, label) {
    ga("send", {
        hitType: "event",
        eventCategory: cat,
        eventAction: action,
        eventLabel: label,
    });
}

// <!-- End Google Analytics -->
