import { getBranding } from "./branding";
import { sendMessageToExt } from "./storage";
import { isFirefox } from "./common";

const branding = getBranding();

const TOP_DOMAIN =
    (typeof branding !== "undefined" && branding.RootDomain) ||
    (function() {
        const parts = window.location.hostname.split(/\./);
        const domain =
            parts.length == 1
                ? window.location.hostname
                : parts.slice(-2).join(".");
        return (
            domain + (window.location.port ? `:${window.location.port}` : "")
        ).toLowerCase();
    })();

const API_ROUTE = "/api/";

const WEB_SEARCH_URL = `https://services.${TOP_DOMAIN}/crx/search.php`;
const WEB_SERVICE_URL = `${API_ROUTE}`;
const SUGGESTION_API = `${API_ROUTE}search/`;
const SUGGESTION_API_INDEXER = `${API_ROUTE}indexer`;
const INDEXER_API_URL = `${API_ROUTE}indexer`;

function openUrl(toOpen, newTab = true) {
    if (!newTab) {
        return window.open(
            toOpen,
            window.self !== window.top ? "_parent" : "_self"
        );
    }
    if (isFirefox) {
        return sendMessageToExt({ task: "openUrl", url: toOpen });
    }
    window.open(toOpen, "_blank");
}

export {
    WEB_SEARCH_URL,
    WEB_SERVICE_URL,
    SUGGESTION_API,
    openUrl,
    TOP_DOMAIN,
    INDEXER_API_URL,
    SUGGESTION_API_INDEXER,
};
